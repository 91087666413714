import React from "react";
import { Container, Link } from "@material-ui/core";
import sifooter from "../Common/assets/osu-footer-logo.png";

function Footer(props = {}) {
  const ExternalLink = (props = {}) => (
    <Link target="_blank" rel="noopener" {...props}></Link>
  );
  return (
    <footer role="contentinfo" className="layout-footer" id="footer" tabIndex="-1">
      <Container>
        <div className="osu-logo">
          <img
            src={sifooter}
            alt="Sustainability Institute at The Ohio State University"
          />
        </div>
        <div className="footer-details">
          <div className="contact">
            <ul>
              <li>
                <h5>Address</h5>
              </li>
              <li className="general-address">
                <ExternalLink href="https://www.osu.edu/map/building.php?building=065">
                  3018 Smith Lab{"\n"}
                  174 W. 18th Avenue{"\n"}
                  Columbus, OH 43210
                </ExternalLink>
              </li>
            </ul>
          </div>
          <div className="contact">
            <ul>
              <li>
                <h5>Contact</h5>
              </li>
              <li>Sustainability Institute</li>
              <li className="contact-links">
                <a href="mailto:sustainability@osu.edu">
                  sustainability@osu.edu
                </a>{" "}
                | <a href="tel:1-614-247-4762">614-247-4762</a>
              </li>
              <li>
                Page maintained by{" "}
                <a
                  className="underline-link"
                  href="mailto:sustainability@osu.edu"
                >
                  Sustainability Institute at Ohio State
                </a>
              </li>
              <li>
                If you have a disability and experience difficulty accessing
                this content, please call the Accessibility Helpline at{" "}
                <a href="tel:1-614-292-5000">614-292-5000</a>.
              </li>
            </ul>
          </div>
          <div className="contact">
            <ul className="social">
              <li>
                <h5>Connect</h5>
              </li>
              <li className="social-list">
                <a className="twitter" href="https://twitter.com/ohiostsustain">
                  <span className="osu-semantic">Twitter</span>
                </a>
                <a
                  className="facebook"
                  href="https://www.facebook.com/OhioStSustain"
                >
                  <span className="osu-semantic">Facebook</span>
                </a>
                <a
                  className="instagram"
                  href="https://www.instagram.com/ohiostsustain"
                >
                  <span className="osu-semantic">Instagram</span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="legal">
          <p>© 2021 The Ohio State University</p> |
          <a href="https://www.osu.edu/privacy/">Privacy Policy</a> |
          <a href="https://equity.osu.edu/sites/default/files/policy110-non-discrimination-notice.pdf">
            Nondiscrimination Notice
          </a>{" "}
          |<a href="https://giveto.osu.edu/">Give to Ohio State</a>
        </div>
      </Container>
    </footer>
  );
}
export default Footer;
