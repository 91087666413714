import { EMERGENCY_ALERT } from "../../actions-index";
import { generateAction } from "../../redux-helpers";
import Parser from "rss-parser";

export function getEmergencyAlert() {
  return async (dispatch) => {
    const dispatchResponse = (response) =>
      dispatch(generateAction(EMERGENCY_ALERT, response));
    let alert = [];
    try {
      const response = await fetchEmergencyAlert();
      if (Array.isArray(response)) alert = response;
    } catch (error) {
      console.error("Error getting emergency alert", error);
    }
    alert.length > 0 && dispatchResponse({ feed: alert });
  };
}

async function fetchEmergencyAlert() {
  let content = [];
  let feed = { items: [] };
  let parser = new Parser();

  try {
    const response = await fetch(process.env.REACT_APP_EMERGENCY_ALERT);
    const text = await response.text();
    feed = await parser.parseString(text);

    feed.items.forEach(function (item, index) {
      content.push({
        ...item,
        id: `emergency-alert-${index}-${encodeURI(item.title)}`,
      });
    });
  } catch (error) {
    console.log("Error fetching alert", error);
  }

  return content;
}
