import React from "react";
import { Mui } from "@osu/react-ui";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Typography, Paper, Grid, List, Button, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "40%",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.up('md')]:{
      width:'50%'
    },
    [theme.breakpoints.up('sm ')]:{
      width:'60%'
    },
    [theme.breakpoints.down('sm')]:{
      width:'80%'
    },
    [theme.breakpoints.down('xs')]:{
      width:'100%'
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button:{
    margin: '16px',
    bottom:'16px',
    position: 'absolute'
  },
  root: {
    width: 300,
    height: 450,
    backgroundColor: "#cdcdcd",
  },
  title: {
    fontSize: 25,
    paddingLeft:10,
    backgroundColor: "gray",
    color: "#fff",
  },
  category: {
    fontSize: 16,
    color: "#666666",
  },
  Button: {
    backgroundColor: "#bb0000",
    color: "#fff",
  },
  school:{
    paddingTop:'20px'
  },
  scroll:{
    overflowX: "hidden",
    overflowY: "auto",
    maxHeight: '300px',
    paddingTop:'20px'
  },
  lineSpacing: {
      paddingTop: "15px",
  },
}));
export default function CardModal({ program }) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const classes = useStyles();
  // const MuiCardActionStyle = {
  //   position: 'absolute',
  //   top: '40%',
  //   left: '50%',
  //   transform: 'translate(-50%, -50%)',
  // }
  return (
    <Box >
      <Button className={classes.button} variant="outlined" onClick={()=>setOpen(true)}>
        Learn More
      </Button>
      <Mui.Modal
        open={open}
        className = {classes.modal}
        onClose = {handleClose}
      >
        <Paper className={classes.paper} key={program.pk} >
          <Typography className={classes.title} color="" gutterBottom>
            {program.data}
          </Typography>
          
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={12} key={"programtype"}>
              <Typography variant="body1" className={classes.school}>
                Department/School: {program.DepartmentName}
              </Typography>
              <Typography variant="body1">
                College: {program.CollegeName}
                <Typography>
                  {program.InfoURL ? <a href={program.InfoURL}>{program.InfoURL}</a> : ""}
                </Typography>
                <Typography variant="body1">Contact:{' '}
                  {program.PrimaryContactName !== "NULL"
                    ? program.PrimaryContactName
                    : ""}
                  {"   "}
                  {program.PrimaryContactEmail ? (
                    <a href={`mailto:${program.PrimaryContactEmail}`}>
                      {program.PrimaryContactEmail}
                    </a>
                  ) : (
                    ""
                  )}
                  {" "}{program.PhoneNumber !== "NULL" ? program.PhoneNumber : ""}
                </Typography>
              </Typography>
              <Typography variant="body1" className={classes.scroll}>{program.Description}</Typography>
              <List></List>
              <Button variant='outlined' onClick={handleClose}>Return to Results</Button>
            </Grid>
          </Grid>
        </Paper>
      </Mui.Modal>
    </Box>
  );
}
