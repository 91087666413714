// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_content_delivery_bucket": "selcportal-20210805134404-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d1cyinjxmco3jj.cloudfront.net"
};


export default awsmobile;
