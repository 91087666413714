import React from "react";
import { Typography } from "@material-ui/core";

const SignOut = () => {
  // useEffect(() => {
  //   generateTitle("Sign Out");
  // });
  return (
    <Typography variant="h5" component="span">
      You have been logged out.
    </Typography>
  );
};

export default SignOut;
