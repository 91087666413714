import API from "@aws-amplify/api";
import _ from "lodash";

export async function GetInterests() {
  const interestsarray = await API.get(
    process.env.REACT_APP_SELC_PORTAL_API_NAME,
    `/public/simpleobjects/Interest`
  );
  return interestsarray;
}

export async function GetKeywordSearch() {
  const keyarray = await API.get(
    process.env.REACT_APP_SELC_PORTAL_API_NAME,
    `/public/simpleobjects/Keyword`
  );
  return keyarray;
}
export async function GetActiveInterests() {
  const intarray = await API.get(
    process.env.REACT_APP_SELC_PORTAL_API_NAME,
    `/public/activeinterests`
  );
  return intarray.interestsData;
}
export async function GetKeywordsAndProgramsByInterestID(interestid) {
  var keyprogarray = await API.get(
    process.env.REACT_APP_SELC_PORTAL_API_NAME,
    `/public/keywordsprograms/${interestid}`
  );
  if (keyprogarray){
    if (typeof(keyprogarray) !== 'undefined' && typeof(keyprogarray.programs) !== 'undefined') {
      for (var item of keyprogarray.keywords) {
        item.KeyName = item.data;
        item.key = item.pk;
        item.Selected = true;
      }
      let programarray = await GetUnique(keyprogarray.programs, "pk");
      keyprogarray.programs = _.sortBy(programarray,["data"]);
      
    }
  return keyprogarray
}
  else {
    return [];
  }
}

export async function GetOneProgramCard(program) {
  console.log("Getting one program card");
  var programInfo = await API.get(
    process.env.REACT_APP_SELC_PORTAL_API_NAME,
    `/admin/program/${program}`
  );
  return programInfo;
}

export async function GetUnique(data, fieldName) {
  const unique = [
    ...new Map(data.map((item) => [item[fieldName], item])).values(),
  ];
  return unique;
}

export async function GetAllProgramTypes() {
  const programtypearray = await API.get(
    process.env.REACT_APP_SELC_PORTAL_API_NAME,
    `/public/simpleobjects/ProgramType`
  );
  return programtypearray;
}
