import React, { Component } from "react";
import AppContextConsumer from "../../../App/context";
import AppBar from "./AdminAppBar";
import OsuNavbar from "./OsuNavbar";
import Account from "./Account";
import PropTypes from "prop-types";

class NavBar extends Component {
  constructor() {
    super();
    this.state = {
      searchRedirect: false,
      showDrawer: false,
    };
    this.updateDrawer = this.updateDrawer.bind(this);
  }
  componentDidMount() {
    if (!Array.isArray(this.props.links) || this.props.links.length === 0) {
      this.props.getNavLinks();
    }
  }
  updateDrawer() {
    this.setState((state) => ({
      showDrawer: !state.showDrawer,
    }));
  }

  render() {
    const { props = {} } = this;
    const { className, id } = props;
    return (
      <AppContextConsumer>
        {(context = {}) => {
          const {
            darkMode,
            setUser,
            signOut,
            user,
            elevation = {},
            setNavHeight,
          } = context;
          return (
            <div>
              <OsuNavbar
                id={id}
                elevation={elevation.navigation || 0}
                className={className}
                darkMode={darkMode}
                user={user}
              />
              <AppBar
                determineHeight={setNavHeight}
                id={id}
                elevation={elevation.navigation || 0}
                className={className}
                darkMode={darkMode}
                user={user}
              >
                <Account
                  darkMode={darkMode}
                  handleLogin={() => setUser(true)}
                  handleLogout={signOut}
                  user={user}
                />
              </AppBar>
            </div>
          );
        }}
      </AppContextConsumer>
    );
  }
}

NavBar.defaultProps = {
  className: "",
  hideSearch: false,
  getNavLinks: () => {},
};

NavBar.propTypes = {
  className: PropTypes.string,
  getNavLinks: PropTypes.func,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          pathname: PropTypes.string.isRequired,
          search: PropTypes.string,
          hash: PropTypes.string,
          state: PropTypes.object,
        }),
      ]).isRequired,
      internal: PropTypes.bool,
      user: PropTypes.shape({
        applicationRoles: PropTypes.string,
        email: PropTypes.string,
        name: PropTypes.string,
        osuid: PropTypes.string,
      }),
      affiliation: PropTypes.array,
    })
  ),
};

export default NavBar;
