import React, { Component, Fragment } from "react";
import { Mui } from "@osu/react-ui";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import { Chip, Grid, Typography, Box } from "@material-ui/core";

import _ from "lodash";
class KeywordProgram extends Component {
  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
    this.sendDataToParent = this.sendDataToParent.bind(this);
    this.state = {
      checked: [...this.props.Keywords.map(a => a.pk)],
      isLoading: true,
      keywordarray: [],
      activecolor: "#bb0000",
    };
  }
  handleSelect = async (keyword) => {
    const currentIndex = this.state.checked.indexOf(keyword.pk);
    const newChecked = [...this.state.checked];
    if (currentIndex === -1) {
      newChecked.push(keyword.pk);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    this.sendDataToParent(newChecked);
    this.setState({ checked: newChecked });
  };
  sendDataToParent = (index) => {
    this.props.receiveDataFromChild(index);
  };
  handleClear = async () => {
    this.setState({ checked: [] });
    this.sendDataToParent([]);
  };
  async componentDidMount() {
    //     try {
    this.setState({ keywordarray: this.props.Keywords, isLoading: false })
  }
  async componentDidUpdate(prevProps) {
    if (this.props.Keywords !== prevProps.Keywords) {
      this.setState({ keywordarray: this.props.Keywords, checked: [...this.props.Keywords.map(a => a.pk)], isLoading: false })
    }
  }
  handleChange(e, { id, value }) {
    this.setState(
      {
        currentProgramType: Object.assign({}, this.state.currentProgramType, {
          [id]: value,
        }),
      },
      () => { }
    );
  }

  render() {
    var { isLoading, checked, keywordarray } = this.state;
    keywordarray = keywordarray
      ? _.orderBy(keywordarray, [(key) => key.data.toLowerCase()], ["asc"])
      : [];

    const filterByKeywordToolTipText = `You can narrow the results of your search by deselecting keywords from the options below.  You can also start fresh by clicking "Clear Selected" which will deselect all the keywords at once.
`;
    if (isLoading) {
      return <div>Loading Keywords...</div>;
    } else {
      return (
        <Fragment >
          <Grid
            className="margin-y-2"
            container

            direction="row"
            justifyContent="space-between"
          >
            <Grid item xs={6}>
              <Typography variant="subtitle" component="div" className="filterKeywordsText">
                Filter By Keywords   <Tooltip title={filterByKeywordToolTipText} placement="top" enterTouchDelay={0}><InfoOutlinedIcon /></Tooltip>
              </Typography>


            </Grid>
            <Grid item xs={6} >
              <Box sx={{ pr: 2}}>
                <Mui.Button

                  className="clearAllLink"
                  variant="contained"
                  href={null}
                  onClick={this.handleClear}
                >
                  CLEAR SELECTED
                </Mui.Button>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="flex-start"
            alignItems="left"
          >
            <Box
              sx={{ px: 4, mt: 1 }}>
              <Grid item xs
              >
                {keywordarray.map((data) => {
                  return (
                    <Chip
                      key={data.pk}
                      color={
                        checked.indexOf(data.pk) !== -1 ? "primary" : "secondary"
                      }
                      label={data.data}
                      onClick={() => this.handleSelect(data)}
                      className={"chip"}
                    />
                  );
                })}
              </Grid>
            </Box>
          </Grid>
        </Fragment>

      );
    }
  }
}
export default KeywordProgram;