import { createTheme } from "@material-ui/core";
import { fonts } from "./typography";
import elevations from "./elevations";
import mapValues from "lodash/mapValues";
import { typeColor } from "../../styles/palette";
import { darken, lighten } from "@material-ui/core/styles";

const defaultTheme = createTheme();

const navBackground = () => ({
  flexGrow: 1,
  zIndex: defaultTheme.zIndex.drawer + 1,
});

const overrides = (darkMode, elevation = {}) => {
  darkMode = false;
  const typePalette = typeColor[darkMode ? "darkMode" : "standard"];
  const shade = darkMode ? "#f5f5f5" : "#000000";

  return {
    MuiFormLabel: {
      root: {
        // color: typePalette.default,
        "&.$Mui-focused": {
          color: typePalette.default,
        },
        color: "#bb0000",
        fontSize: "medium",
        fontWeight: "bold",
      },
    },
    MuiFormControl: {
      root: {
        paddingRight: ".5em",
        paddingLeft: ".5em",
        backgroundColor: "#ffffff",
        color: "#f5f5f5",
      },
    },
    MuiFormControlLabel: {
      label: {
        color: typePalette.default,
        "&.$Mui-disabled": {
          color: lighten(shade, 0.4),
        },
      },
    },
    MuiContainer: {
      root: {
        paddingLeft: "8px",
        paddingRight: "8px",
        "&.MuiContainer-maxWidthLg": {
          paddingTop: "20px",
        },
      }
    },
    // MuiToggleButton: {
    //   label: {
    //     color: `var(--root-text-color)`,
    //     // "&.$Mui-disabled": {
    //     //   color: lighten(shade, 0.4),
    //     // },
    //   },
    //   root: {
    //     backgroundColor: `rgba(0, 0, 0, 0.1)`,
    //     // "&.$Mui-disabled": {
    //     //   backgroundColor: darken(shade, 0.4),
    //     // },
    //     "&.$Mui-selected": {
    //       backgroundColor: `rgba(0, 0, 0, 0.1)`,
    //       border: "1px solid #bb0000",
    //     },
    //   },
    // },
    MuiCard: {
      root: {
        backgroundColor: "#fff",
      },
    },
    MuiCardHeader: {
      title: {
        color: "#000000",
        fontSize: 16,
        textAlign: "left",
        backgroundColor: "#f5f5f5",
      },
      subheader: {
        color: "#c2c2c2",
      },
    },
    MuiBadge: {
      root: {
        padding: "10px",
      },
    },
    MuiCheckbox: {
      root: darkMode
        ? {
            color: darken(shade, 0.4),
          }
        : {},
    },
    MuiSelect: {
      select: {
        color: `var(--root-text-color)`,
        backgroundColor: "#ffffff!important",
      },
    },
    MuiDataGrid: {
      root: {
        color: `var(--root-text-color)`,
      },
    },
    ReactModal__Content: {
      backgroundColor: `var(--root-background-color)`,
    },
    MuiInputBase: {
      input: {
        color: `var(--root-text-color)`,
        backgroundColor: `#ffffff`,
        minWidth: `200px`,
        width: "330px",
      },
    },
    MuiTablePagination: {
      root: {
        backgroundColor: `rgba(0, 0, 0, 0.1)`,
        color: `rgba(0, 0, 0)`,
      },
    },
    MuiIconButton: {
      root: {
        color: typePalette.action,
      },
    },

    MuiInputLabel: {
      root: {
        color: "#f5f5f5",
        backgroundColor: "#ffffff",
      },
      formControl: { 
        transform: "inherit", left: "7px" 
      },
    },
    //added by Laurie to fix chip spacing on keywords
    MuiButtonBase: {
      root: {
        margin: "0px 10px 10px 0px",
        "&:focus": {
          outline: "-webkit-focus-ring-color auto 5px",
        },
      },
    },
    MuiLink: {
      root: {
        fontWeight: 600,
      },
      underlineHover: {
        textDecoration: "none",
      },
    },
    MuiPaper: {
      outlined: darkMode
        ? {
            border: "1px solid rgba(255,255,255, 0.12)",
          }
        : {},
      root: {
        color: shade,
      },
      ...(darkMode
        ? mapValues(elevations, (item) => ({ ...item, boxShadow: "none" }))
        : {}),
    },
    MuiTypography: {
      root: {
        color: shade,
      },
      colorPrimary: {
        color: "#2f66af",
      },
      colorTextSecondary: {
        color: typePalette.secondary,
      },
      h2: {
        fontSize: `1.125rem`,
        fontWeight: `500`,
        lineHeight: `1.6`,
        "@media (min-width: 1280px)": {
          fontSize: `1.25rem`,
        },
        "@media (min-width:960px)": {
          fontSize: `1.25rem`,
        },
        "@media (min-width:600px)": {
          fontSize: `1.25rem`,
        },
      },
      h1: {
        fontSize: `1.125rem`,
        fontWeight: `500`,
        lineHeight: `1.6`,
        "@media (min-width: 1280px)": {
          fontSize: `1.25rem`,
        },
        "@media (min-width:960px)": {
          fontSize: `1.25rem`,
        },
        "@media (min-width:600px)": {
          fontSize: `1.25rem`,
        },
      },
    },
    MuiBottomNavigationAction: {
      root: {
        color: typePalette.secondary,
      },
    },
    MuiSvgIcon: {
      colorPrimary: {
        fill: typePalette.action,
      },
      colorSecondary: {
        fill: typePalette.secondary,
      },
    },
    MuiCssBaseline: {
      "@global": {
        "@font-face": fonts,
      },
    },
    MuiListSubheader: {
      root: {
        color: shade,
      },
    },
    MuiAppBar: {
      root: {
        ...navBackground(),
      },
    },
    Mui: {
      root: {
        "&$selected": {
          backgroundColor: "rgba(187, 0, 0, 0.7)",
        },
      },
    },
    MuiBottomNavigation: {
      root: {
        ...navBackground(),
        ...(darkMode ? elevations[`elevation${elevation.navigation}`] : {}),
        overflow: "hidden",
        position: "fixed",
        bottom: 0,
        width: "100%",
      },
    },
    MuiNativeSelect: {
      root: {
        color: shade,
      },
      select: {
        color: shade,
        "&.option": {
          backgroundColor: "#ffffff",
        },
      },
      selectMenu: {
        color: shade,
      },
      icon: {
        color: shade,
      },
    },
    MuiInput: {
      underline: {
        "&:after": {
          borderBottom: `1px solid ${shade}`,
        },
        "&:before": {
          borderBottom: `1px solid ${shade}`,
        },
        "&:hover&:after": {
          borderBottom: `1.5px solid ${shade}`,
        },
        "&:hover&:before": {
          borderBottom: `1.5px solid ${shade}`,
        },
      },
    },
    MuiDivider: {
      root: darkMode
        ? {
            backgroundColor: "rgba(245,245,245, 0.12)",
          }
        : {},
    },
    MuiListItem: {
      divider: darkMode
        ? {
            borderBottomColor: "rgba(255,255,255, 0.12)",
          }
        : {},
    },
    MuiListItemIcon: {
      root: {
        color: shade,
      },
    },
    MuiOutlinedInput: {
      notchedOutline: darkMode
        ? {
            borderColor: "rgba(255,255,255, 0.12)",
          }
        : {},
    },
    MuiSlider: {
      markLabel: darkMode
        ? {
            color: "rgba(255,255,255, 0.50)",
          }
        : {},
      markLabelActive: darkMode
        ? {
            color: "rgba(255,255,255, 0.90)",
          }
        : {},
    },
    MuiSkeleton: {
      root: {
        backgroundColor: `rgba(${darkMode ? `245, 245, 245` : `0, 0, 0`}, 0.1)`,
      },
    },
    MuiTable: {
      root: {
        backgroundColor: `rgba(${darkMode ? `245, 245, 245` : `0, 0, 0`}, 0.1)`,
      },
    },
    MuiTableFooter: {
      root: {
        backgroundColor: `rgba(${darkMode ? `245, 245, 245` : `0, 0, 0`}, 0.1)`,
      },
    },
    MuiTableBody: {
      root: {
        backgroundColor: `rgba(${darkMode ? `0, 0, 0` : `245, 245, 245`}, 0.1)`,
        color: `var(--root-text-color)`,
      },
    },
    MuiTableCell: {
      head: {
        backgroundColor: "#BB0000",
        color: "#fff",
      },
      body: {
        backgroundColor: `rgba(${darkMode ? `0, 0, 0` : `245, 245, 245`})`,
        color: `var(--root-text-color)`,
      },
    },
    MuiPaperInGridRoot: {
      flexGrow: 1,
    },
    MuiPaperInGrid: {
      root: {
        textAlign: "center",
        color: typePalette.secondary,
      },
      textAlign: "center",
      color: typePalette.secondary,
    },
    default: {
      color: "#000",
    },
  };
};

export default overrides;
