import { Mui } from "@osu/react-ui";
import React from "react";

const useStyles = Mui.makeStyles((theme) => {
  return {
    select: ({ minWidth }) => ({
      minWidth,

      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#b2b3b3",
      },
  }),
  }
});

const Select = (props = {}) => {
  const { label, name, id, options, minWidth, onChange, value, className, helperText, ...rest } =
    props;
  const classes = useStyles({ minWidth });

  return (
    <Mui.FormControl className={`${classes.select} ${className}`.trim()} variant="outlined">
      <Mui.InputLabel htmlFor={id} id={id + "-label"}>
        {label}
      </Mui.InputLabel>
      <Mui.Select
        native
        value={value}
        onChange={(event) =>{
          onChange({
            name: event?.target?.name || name,
            value: event?.target?.value,
          })
        }}
        label={label}
        labelId={id + "-label"}
        inputProps={{
          name,
          id,
        }}
        {...rest}
      >
        {options.map((opt = {}, index) => {
          const { value: optValue, label: optLabel, id: optId, ...rest } = opt;
          return (
            <option
              id={optId}
              value={optValue}
              key={`${id}#${optId}#${index}`}
              {...rest}
            >
              {optLabel}
            </option>
          );
        })}
      </Mui.Select>
      <Mui.FormHelperText>{helperText}</Mui.FormHelperText>
    </Mui.FormControl>
  );
};

Select.defaultProps = {
  minWidth: `15rem`,
  className: '',
  options: [],
};

export default Select;
