import React from "react";
import { Mui } from "@osu/react-ui";
import { Container } from "@material-ui/core";
import InterestSelect from "./InterestSelect";
import AnnouncementBanner from "./AnnouncementBanner";


function ExploreBox(props = {}) {

  const useStyles = Mui.makeStyles((theme) => ({

    box: {
      position: 'relative',
      top: '-257px',
      transform: 'translate(-50%,0%)',
      left: '50%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      [theme.breakpoints.up('sm')]: {
        width: '80%',
      },
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.box}>
      <Container className="exploreProgramsBox">
        <div className="block-title">EXPLORE</div>
        <InterestSelect />
      </Container>
      <AnnouncementBanner />
    </div>
   

  )
}


export default ExploreBox;