import React,  { Fragment }  from "react";
import { Mui } from "@osu/react-ui";
import { Grid, Button, Paper, Typography } from "@material-ui/core";


function AnnouncementBanner(props = {}) {

  const useStyles = Mui.makeStyles((theme) => ({
    root: {   
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        position: 'relative',
        padding: "35px",
      },
      box: {
        position: 'relative',
        transform: 'translate(-50%,0%)',
      
        left: '50%',
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        [theme.breakpoints.up('xs')]: {
          width: '85%',
        },
        [theme.breakpoints.down('md')]: {
          width: '95%',
        },
       
        [theme.breakpoints.down('xl')]: {
          width: '90%',
        },
        
      },
     
  }));

  const classes = useStyles();

  return (
        <Fragment>
             <Grid
                className={classes.box}
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start" >
               <Grid item xs={12} md={6} xl={4}>
                    <Paper className={classes.root} elevation={0} display="block">
                         <Button variant="outlined" target="_blank" rel="noopener noreferrer" href="https://si.osu.edu/sustainability-action-database">
                             Sustainability Projects and Research 
                          </Button>
                             <Typography variant="body1"  gutterBottom>
                             Check out the many ways that Buckeyes are advancing sustainability through living lab research and unique project-based learning opportunities.
                            </Typography>
                    </Paper>
                 </Grid>

                 <Grid item xs={12} md={6} xl={4}>
                     <Paper className={classes.root} elevation={0} display="block">
                         <Button  variant="outlined" href="mailto:ExploreSustainability@osu.edu" >
                             Contact Education and Learning Staff
                         </Button>
                         <Typography variant="body1"  gutterBottom>
                         For further information about this web tool or understanding how different programs align with your interest in sustainability, please feel free to contact us.
                        </Typography>
                      </Paper>
                    </Grid>
             </Grid>
        </Fragment> 

  )
}

export default AnnouncementBanner;
