import React, { Fragment } from "react";
import "./styles/index.css";

function Home() {
  return (
    <Fragment>
      {" "}
      <h1 className="h-accessible-text">SELC Portal</h1>{" "}
    </Fragment>
  );
}

export default Home;
