import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { redirectToLogIn } from "../../util/util";
import PropTypes from "prop-types";
import { Typography, CircularProgress, Button } from "@material-ui/core";
import { PROJECT_NAME } from "../../util/constants";

class SignIn extends Component {
  componentDidMount() {
    this.props.setUser();
  }

  render() {
    const { status } = this.props;
    if (status === "loading") {
      return (
        <Typography
          className="d-flex flex-column align-items-center"
          component="span"
          variant="h5"
        >
          Logging in...
          <CircularProgress
            className="mt-2"
            width={60}
            height={60}
            color="primary"
          />
        </Typography>
      );
    } else if (status === "error") {
      return (
        <Typography
          className="d-flex flex-column align-items-center"
          component="span"
          variant="h5"
        >
          Log in to {PROJECT_NAME}
          <Button
            className="mt-2"
            variant="contained"
            color="primary"
            aria-label={`Log in to ${PROJECT_NAME}`}
            onClick={() => redirectToLogIn()}
          >
            Log In
          </Button>
        </Typography>
      );
    }
    return <Redirect to="/admin" />;
  }
}

SignIn.propTypes = {
  status: PropTypes.oneOf(["", "error", "success", "loading"]),
  user: PropTypes.shape({
    applicationRoles: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string,
    osuid: PropTypes.string,
  }),
  setUser: PropTypes.func,
};

export default SignIn;
