import API from "@aws-amplify/api";
import { Auth } from "aws-amplify";

export async function GetPrograms() {
  const progarray = await API.get(
    process.env.REACT_APP_SELC_PORTAL_API_NAME,
    "/admin/allprograms",
    {
      headers: {
        Authorization: (await Auth.currentSession()).idToken.jwtToken,
      },
    }
  );
  return progarray;
}

export async function GetRelatedPrograms() {
  const progarray = await API.get(
    process.env.REACT_APP_SELC_PORTAL_API_NAME,
    "/public/allrelatedprograms"
  );
  return progarray;
}

export async function GetActiveRelatedPrograms(programtype) {
  var progarray = await API.get(
    process.env.REACT_APP_SELC_PORTAL_API_NAME,
    "/public/allrelatedprograms"
  );
  if (programtype){
    progarray = progarray.filter((item)=>{
      return item.ProgramType === programtype
    })
  }
  return progarray.filter((item) => {
    return item.Visible.toLowerCase() === "true";
  });
}
// export async function GetStateDropdown() {
//   const stateArray = [
//     "AL",
//     "AK",
//     "AS",
//     "AZ",
//     "AR",
//     "CA",
//     "CO",
//     "CT",
//     "DE",
//     "DC",
//     "FM",
//     "FL",
//     "GA",
//     "GU",
//     "HI",
//     "ID",
//     "IL",
//     "IN",
//     "IA",
//     "KS",
//     "KY",
//     "LA",
//     "ME",
//     "MH",
//     "MD",
//     "MA",
//     "MI",
//     "MN",
//     "MS",
//     "MO",
//     "MT",
//     "NE",
//     "NV",
//     "NH",
//     "NJ",
//     "NM",
//     "NY",
//     "NC",
//     "ND",
//     "MP",
//     "OH",
//     "OK",
//     "OR",
//     "PW",
//     "PA",
//     "PR",
//     "RI",
//     "SC",
//     "SD",
//     "TN",
//     "TX",
//     "UT",
//     "VT",
//     "VI",
//     "VA",
//     "WA",
//     "WV",
//     "WI",
//     "WY",
//   ];
//   const stateDropdown = stateArray.map(function (tcid) {
//     return {
//       value: tcid,
//       text: tcid,
//       key: tcid,
//     };
//   });
//   return stateDropdown;
// }

// export async function GetCareerPaths(programids) {
//   var careerpaths = [];
//   for (let element of programids) {
//     var paramsProg = {
//       operation: "read",
//       payload: {
//         IndexName: "sk-data-index",
//         KeyConditionExpression: "sk = :objecttype and #data = :progid",
//         ExpressionAttributeValues: {
//           ":progid": element,
//           ":objecttype": "CareerPath",
//         },
//         ExpressionAttributeNames: {
//           "#data": "data",
//         },
//       },
//     };
//     const response = await API.post(
//       process.env.REACT_APP_SELC_PORTAL_API_NAME,
//       "/admin",
//       {
//         body: paramsProg,
//         headers: {
//           Authorization: (await Auth.currentSession()).idToken.jwtToken,
//         },
//       }
//     );
//     for (var elem of response.Items) {
//       elem.CareerName = elem.Name;
//       elem.isSelected = "true";
//     }
//     careerpaths = careerpaths.concat(response.Items);
//     //   return response.Items;
//   }
//   return careerpaths.map((item) => item.Name);
// }
