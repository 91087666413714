import React, { Component, lazy } from "react";
import { CssBaseline, Container } from "@material-ui/core";
import Amplify, { Auth } from "aws-amplify";
import IdleTimer from "react-idle-timer";
import aws_exports from "../../aws-exports";
import apiConfig from "../../api-config";
import CustomAuthStorage from "../../util/CustomAuthStorage";
import { responsiveFontSizes } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import theme from "../styles/theme";
import PropTypes from "prop-types";
import { AppContext } from "../context";
import { standardPalette, darkModePalette } from "../styles/colors";
import Footer from "../../Components/Footer";
import NavBar from "../../Components/Navbar/containers/Adminindex";
import EmergencyAlert from "../../Components/EmergencyAlert/containers";
import { redirectToLogIn } from "../../util/util";
import { Typography, CircularProgress, Button } from "@material-ui/core";
import { PROJECT_NAME } from "../../util/constants";

Auth.configure({ storage: new CustomAuthStorage(apiConfig.customAuthStorage) });
Amplify.configure({ ...aws_exports, ...apiConfig });
const FIFTEEN_MINUTES = 60000 * 15;
class PrivateRoute extends Component {
  constructor(props) {
    super(props);
    const matchMedia = window.matchMedia(
      "(prefers-color-scheme: light)"
    ).matches;
    const validUser =
      props.user && props.user.status && props.user.status === "success";
    if (matchMedia) {
      this.updateColorVariables(matchMedia);
    }
    this.state = {
      darkMode: matchMedia,
      navIndex: 1,
      title: "",
    };
    if (!validUser && this.props.location.pathname !== "/logout") {
      this.props.setUser();
    }
    this.constructProviderValue = this.constructProviderValue.bind(this);
    this.setNavigationHeight = this.setNavigationHeight.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.createTitle = this.createTitle.bind(this);
  }
  state = {
    user: {},
    isLoading: true,
  };
  async componentDidMount() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      this.setState({ user, isLoading: false });
    } catch (err) {
      this.setState({ isLoading: false });
    }
  }
  updateColorVariables(darkMode) {
    let root = document.documentElement;
    const palette = darkMode ? darkModePalette : standardPalette;

    root.style.setProperty("--root-text-color", palette.textColor);
    root.style.setProperty(
      "--options-background-color",
      darkMode ? `var(--elevation8)` : palette.background.default
    );
    root.style.setProperty(
      "--root-background-color",
      palette.background.default
    );
  }
  createTitle =
    (page = "") =>
    (description = "") => {
      this.setState({
        title: `${page}${description ? ` | ${description}` : ""}`,
      });
    };

  updateColorMode(toggle = false) {
    this.setState(
      {
        darkMode: toggle,
      },
      () => this.updateColorVariables(this.state.darkMode)
    );
  }

  setNavigationHeight(height) {
    this.setState({ navHeight: height });
  }
  constructProviderValue() {
    const { navHeight } = this.state;
    return {
      mobileNavIndex: this.state.navIndex,
      user: this.props.user,
      signOut: this.handleLogoutClick,
      setUser: this.handleLoginClick,
      darkMode: this.state.darkMode,
      navHeight,
      palette: this.state.darkMode ? darkModePalette : standardPalette,
      setNavHeight: (height) =>
        !!height && height !== navHeight && this.setNavigationHeight(height),
      elevation: this.state.darkMode
        ? {
            standard: 2,
            high: 4,
            navigation: 6,
            tabs: 6,
            drawer: 12,
          }
        : {
            standard: 1,
            high: 4,
            tabs: 2,
            navigation: 4,
            drawer: 12,
          },
    };
  }
  _onIdle(e) {
    Auth.signOut();
  }
  render() {
    const { user, location } = this.props;
    const AppRoutes = lazy(() => import("./Routes"));
    const providerValue = this.constructProviderValue();
    if (user.status === "loading") {
      return (
        <Typography
          className="d-flex flex-column align-items-center"
          component="span"
          variant="h5"
        >
          Loading...
          <CircularProgress
            className="mt-2"
            width={60}
            height={60}
            color="primary"
          />
        </Typography>
      );
    } else if (user.status === "error" || location.pathname === "/logout") {
      return (
        <Typography
          className="d-flex flex-column align-items-center"
          component="span"
          variant="h5"
        >
          Log in to {PROJECT_NAME}
          <Button
            className="mt-2"
            variant="contained"
            color="primary"
            aria-label={`Log in to ${PROJECT_NAME}`}
            onClick={() => redirectToLogIn()}
          >
            Log In
          </Button>
        </Typography>
      );
    } else if (user.status === "success") {
      return (
        <React.Fragment>
          <IdleTimer
            ref={(ref) => {
              this.idleTimer = ref;
            }}
            onIdle={this.onIdle}
            timeout={FIFTEEN_MINUTES}
          />
          <AppContext.Provider value={providerValue}>
            <ThemeProvider
              theme={responsiveFontSizes(
                theme(this.state.darkMode, providerValue.elevation)
              )}
            >
              <CssBaseline />
              <div className="main-wrapper">
                {process.env.REACT_APP_ENABLE_EMERGENCY_ALERT === "true" && (
                  <EmergencyAlert className="py-1" />
                )}
                <NavBar
                  hideSearch={this.props.location.pathname.includes("search")}
                  toggleDarkMode={(toggle) => this.updateColorMode(toggle)}
                  className="mb-2"
                />
                <Container component="main" id="main" className="container" tabIndex="-1">
                  <AppRoutes
                    pathname={this.props.location.pathname}
                    user={this.props.user}
                  />
                </Container>
              </div>
              <Footer className="mt-3" />
            </ThemeProvider>
          </AppContext.Provider>
        </React.Fragment>
      );
    }
    return <div></div>;
  }
  handleLogoutClick = () => {
    Auth.signOut();
  };
}
PrivateRoute.propTypes = {
  user: PropTypes.shape({
    applicationRoles: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string,
    osuid: PropTypes.string,
    username: PropTypes.string,
  }),
  setUser: PropTypes.func,
};
export default React.memo(
  PrivateRoute,
  (prev, next) => JSON.stringify(prev) === JSON.stringify(next)
);
