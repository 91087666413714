import React, { Component, Suspense } from "react";
import { Auth } from "aws-amplify";
import SignInContainer from "../../Authentication/containers/SignIn";
import SignOut from "../../Authentication/components/SignOut";
import { Switch } from "react-router-dom";
import Home from "../../Components/Home";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      darkMode: matchMedia,
      navIndex: 1,
      programdata: [],
      isLoading: true,
      title: "",
    };
    this.createTitle = this.createTitle.bind(this);
  }
  state = {
    user: {},
    isLoading: true,
  };
  async componentDidMount() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      this.setState({ user, isLoading: false });
    } catch (err) {
      this.setState({ isLoading: false });
    }
  }
  createTitle =
    (page = "") =>
    (description = "") => {
      this.setState({
        title: `${page}${description ? ` | ${description}` : ""}`,
      });
    };
  render() {
    const createTitle =
      (page = "") =>
      (description = "") => {
        this.setState({
          title: `${page}${description ? ` | ${description}` : ""}`,
        });
      };
    const { user, location } = this.props;
    if (this.state.isLoading) {
      return <div></div>;
    } else {
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <PublicRoute
              restricted={false}
              component={Home}
              path="/"
              user={user}
              generateTitle={createTitle("SELC Portal")}
              exact
            />
            <PublicRoute
              restricted={true}
              component={SignInContainer}
              user={user}
              darkMode={this.state.darkMode}
              path="/login"
            />
            <PublicRoute
              restricted={true}
              component={SignOut}
              user={user}
              darkMode={this.state.darkMode}
              path="/logout"
            />
            <PrivateRoute
              path="/admin/:path?"
              user={user}
              setUser={this.props.setUser}
              darkMode={this.state.darkMode}
              location={location}
              generateTitle={createTitle("SELC Portal Sign In")}
            />
          </Switch>
        </Suspense>
      );
    }
  }
}
export default React.memo(
  App,
  (prev, next) => JSON.stringify(prev) === JSON.stringify(next)
);
