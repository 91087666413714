export const standardColor = {
    red: "#BB0000",
    gray: "#666666",
    orange: "#C04F24",
    brown: "#453831",
    green: "#73792D",
    teal: "#1C7C89",
    blue: "#476C97",
    purple: "#5D3F82",
    pink: "#851E5E",
    darkGreen: "#0F610A"
}

export const darkModeColor = {
    red: "#BB0000",
    gray: "#666666",
    orange: "#C04F24",
    brown: "#453831",
    green: "#73792D",
    teal: "#1C7C89",
    blue: "#476C97",
    purple: "#5D3F82",
    pink: "#851E5E",
    darkGreen: "#0F610A"
}

export const typeColor = {
    standard: {
        action: '#2f66af',
        primary: '#2f66af',
        secondary: standardColor.gray,
        default: "#000000",
        error: standardColor.red
    },
    darkMode: {
        action: '#2f66af',
        primary: '#2f66af',
        secondary: standardColor.gray,
        default: "#000000",
        error: standardColor.red
    }
}

export const rootDarkColor = '#ffffff'
