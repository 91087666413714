import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import NavBar from "../components/Adminindex";

const mapStateToProps = (state = {}) => {
  const { navigation = {}, globalSearch = {} } = state;
  const { links = [] } = navigation;
  const { query = "" } = globalSearch;
  return {
    links,
    query,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar));
