function transformUser(obj = {}) {
  const {
    applicationRoles,
    email,
    displayName,
    name,
    osuid,
    given_name = "",
    family_name = "",
  } = obj;
  const formattedUser = {
    applicationRoles: applicationRoles,
    email: email,
    name: displayName ? displayName : name,
    osuid: osuid,
    initials: `${given_name && given_name.charAt(0)}${
      family_name && family_name.charAt(0)
    }`.toUpperCase(),
  };

  return formattedUser;
}

export { transformUser };
