import React, {useRef, useEffect} from "react";
import {AppBar as MuiAppBar, Toolbar, Container, ButtonBase} from "@material-ui/core/";
import "../styles/index.css";
import PropTypes from "prop-types";
import SILOGO from "../../../Common/SILOGO.png";
// import icon from "../../../Common/"
function AppBar(props) {
  const {determineHeight, className, elevation} = props;
  const ref = useRef({current: {clientHeight: null}});

  useEffect(() => {
    determineHeight(ref.current.clientHeight);
  }, [ref, determineHeight]);

  return (
    <MuiAppBar ref={ref} elevation={elevation} className={className} position="sticky" color="inherit">
      <Toolbar>
        <Container>
          <ButtonBase aria-label="Navigate to Home" href="https://si.osu.edu/">
            {/* <Typography
              color="secondary"
              classes={{ root: classes.tabs }}
              className="pr-2"
              variant="h6"
              component="span"
              noWrap
            >
              Back to Sustainability Institute
            </Typography> */}
            <img src={SILOGO} style={{width: `290px`, hight: `42px`}} alt="Sustainability Institute" />
          </ButtonBase>
          {props.children}
        </Container>
      </Toolbar>
    </MuiAppBar>
  );
}

export default AppBar;

AppBar.defaultProps = {
  className: "",
  elevation: 0,
  determineHeight: (height) => ({height}),
};

AppBar.propTypes = {
  elevation: PropTypes.number,
  className: PropTypes.string,
  darkMode: PropTypes.bool,
  determineHeight: PropTypes.func,
};
