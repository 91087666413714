import React, { Component, Fragment } from "react";
import { Container, Grid, CircularProgress, TextField } from "@mui/material";
import Select from "../../Common/components/Select";
import {GetKeywordsAndProgramsByInterestID, GetUnique, GetActiveRelatedPrograms, GetActiveInterests} from "../../actions-index";
import KeywordProgram from "./KeywordProgram";
import ProgramCards from "./ProgramCards";
import MinorsCertificatePrograms from "./MinorsCertificatePrograms";

class InterestSelect extends Component {
  constructor(props) {
    super(props);
    this.gridobj = React.createRef();
    this.handleProgramTypeChange = this.handleProgramTypeChange.bind(this);
    this.setDataReceivedFromChild = this.setDataReceivedFromChild.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.state = {
      isLoading: true,
      isLoadingKeysProgs: false,
      query: "",
      interestid: "",
      programtypeid: "",
      programdata: [],
      relatedprograms: [],
      relatedprogramscopy: [],
      currentInterest: {
        data: "",
        pk: "",
      },
      currentKeyword: {
        data: "",
        pk: "",
      },
      keywordarray: [],
      interestCategories: [],
      uniqueInterest: [],
      programtypes: [],
      interests: [],
      interestsData:[]
    };
  }

  async componentDidMount() {
    const interestsData = await GetActiveInterests();
    this.setState({interestsData})
    const progTypes = [];
    progTypes.push({
      ObjectType: "Program",
      pk: "type-0",
      sk: "ProgramType",
      data: "Core Majors",
    });
    progTypes.push({
      ObjectType: "RelatedProgram",
      pk: "type-1",
      sk: "ProgramType",
      data: "Related Majors, Minors and Certificates",
    });
    
    this.setState({programtypes: progTypes});

    //Set Program Type Default Value
    this.setState({programtypeid: "Core Majors"});

    //Load the list of interests for the Major Programs
    this.LoadInterests("Core Majors");

    this.setState({isLoading: false});
  }

  async LoadInterests(value = null) {
    if (value) {
      var objecttype = (this.state.programtypes.find((ObjectType) => ObjectType.data === value) !== undefined) ? this.state.programtypes.find((ObjectType) => ObjectType.data === value).ObjectType : (value === "Core Majors") ? "Program" : "RelatedProgram";

      if (objecttype === "Program") {
        var activeinterests = this.state.interestsData;
        if (value === "Core Majors") {
          const intCategories = [];

          if (activeinterests.length > 0) {
            for (let i=0; i <= activeinterests.length; i++) {
              if (i === 0) {
                  intCategories.push({
                    pk: "",
                    sk: "Interest",
                    data: ""
                  });
              }
              else {
                intCategories.push({
                  pk: activeinterests[i-1].pk,
                  sk: "Interest",
                  data: activeinterests[i-1].data
                });
              }
            }
          }

          this.setState({ interestCategories: intCategories });
        }
        else {
          this.setState({ interestCategories: activeinterests });
        }

        this.setState({relatedprograms: [], relatedprogramscopy: [] });

      } else if (objecttype === "RelatedProgram") {

        this.setState({ isLoadingKeysProgs: true });

        await GetActiveRelatedPrograms().then((response) => {

          this.setState({ relatedprograms: response, relatedprogramscopy: response });
        });

        this.setState({ isLoadingKeysProgs: false });

        //There are NO interests for Minors/Certificates
        this.setState({interestCategories: []});
      } else {
        this.setState({ relatedprograms: [], relatedprogramscopy: [] });
      }
    } else {
      this.setState({ relatedprograms: [], relatedprogramscopy: [], interestCategories: [] });
    }
  }

  handleInterestChange = async (e) => {
    //id== pk, value == interest #
    this.setState({interestid: e.value, programdata: [], keywordarray: []});
 
    if (e.value) {
      this.setState({isLoadingKeysProgs: true});
      var keywordprogramarray = await GetKeywordsAndProgramsByInterestID(e.value);

      this.setState(
        {
          currentInterest: Object.assign({}, this.state.currentInterest, {
            [e.id]: e.value,
          }),
          keywordarray: (typeof(keywordprogramarray) !== 'undefined' && typeof(keywordprogramarray.keywords) !== 'undefined') ? keywordprogramarray.keywords : [],
          programdata: (typeof(keywordprogramarray) !== 'undefined' && typeof(keywordprogramarray.programs) !== 'undefined') ? keywordprogramarray.programs : [],
          allprogramdata: (typeof(keywordprogramarray) !== 'undefined' && typeof(keywordprogramarray.programs) !== 'undefined') ? keywordprogramarray.programs : [],
          keyprogjoins: (typeof(keywordprogramarray) !== 'undefined' && typeof(keywordprogramarray.keyprogjoins) !== 'undefined') ? keywordprogramarray.keyprogjoins : [],
          isLoadingKeysProgs: false
        },
        () => {
          this.setState({
            interestid: e.value,
          });
        }
      );
    } else {
    }
  
    if (!e.value) {
      var array = [];
      this.setDataReceivedFromChild(array);
    } 
  };

  handleProgramTypeChange = async (e) => {
    this.setState({ programtypeid: e.value, programdata: [], interestid: "", keywordarray: [] });
    this.LoadInterests(e.value);

  }

  handleInputChange = async(e) => {
    this.setState({ query: e.target.value });

    //Remove Special characters from the string
    //let strToSearch = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
    //Allow Special characters in the string
    let strToSearch = e.target.value.replace(/[^a-zA-Z0-9!@#~%\\$&()-_`.+,/"| ]/g, "");

    this.setState(
      {
        query: strToSearch,
      },
      () => {
        this.filterArray();
      }
    );
  };

  escapeSpecialCharacters = (str = null) => {
    const specialChars = ` \`!@#$%^&*()_+-=[]{};':"|,.<>/?~`;
    let specialCharsArr = specialChars.split("");

    specialCharsArr.forEach((specialChar) => {
      if (str.includes(specialChar)) {
        str = str.replaceAll(specialChar, "\\" + specialChar);
      }
    });

    return str;
  };

  filterArray = () => {
    const {query, relatedprogramscopy} = this.state;
    this.setState({relatedprograms: relatedprogramscopy});

    let searchString = "";
    let searchStringArr = query.split(" ");
    searchStringArr.forEach((element) => {
      element = this.escapeSpecialCharacters(element);
      searchString = searchString + "(?=.*" + element + ")";
    });

    try {
      const regexp = new RegExp(searchString, "i");
      let filterResponseData = relatedprogramscopy;

      if (query.length > 0) {
        filterResponseData = filterResponseData.filter((element) => {
          return element.data.toLowerCase().search(regexp) > -1 || element.ProgramType.toLowerCase().search(regexp) > -1;
        });

        this.setState({ relatedprograms: filterResponseData });
      } else {
        this.setState({ relatedprograms: relatedprogramscopy });
      }
    } catch (e) {
      let filterResponseData = [];

      this.setState({ relatedprograms: filterResponseData });
    }
  };

  setDataReceivedFromChild = async (index) => {
    let programarray = this.state.keyprogjoins.filter((item) => index.includes(item.KeywordID));
    let result = programarray.map((a) => a.data);
    programarray = this.state.allprogramdata.filter((item) => result.includes(item.pk));
    let programdata = await GetUnique(programarray, "pk");

    this.setState({programdata});
  };

  render() {
    if (this.state.isLoading) {
      return <Grid container className={"root"} spacing={3} justifyContent="center"><CircularProgress size={24} className="margin-top-6" /></Grid>;
    } else {
      const interestid = this.state.interestid;
      const programtypeid = this.state.programtypeid;
      const relatedprograms = this.state.relatedprograms;
      var interestCategories;

      if (this.state.interestCategories) {
        interestCategories = this.state.interestCategories.map((coll) => ({
            value: coll.pk,
            label: coll.data,
            key: coll.pk,
        }));
      }

      const programtypes = this.state.programtypes.map((item) => ({
        value: item.data,
        label: item.data,
        key: item.pk,
      }));

      return (
            <Fragment>
            <Container>
            <Grid
              container
              justifyContent="center"
            >
              <Select 
                id="value"
                name="ProgramTypes"
                className='margin-bottom-1'
                label="Search By"
                value={programtypeid}
                options={programtypes}
                onChange={this.handleProgramTypeChange}
              />{" "}
             
              { programtypeid !== "Core Majors"?
              (<TextField
                id="ProgramsFilter"
                name="RelatedProgramsFilter"
                label="Search Related Majors, Minors and Certificates"
                variant="outlined"
                onChange={this.handleInputChange}
                sx={(theme) => ({
                    [theme.breakpoints.up('sm')]: {
                      width: "380px", 
                    },
                    [theme.breakpoints.down('sm')]: {
                      width: "380px", 
                    },
                    [theme.breakpoints.between(0,281)]: {
                      width: "255px",
                    },
                    [theme.breakpoints.between(281,361)]: {
                      width: "300px",
                    },
                    [theme.breakpoints.between(361,415)]: {
                      width: "350px",
                    },
                })}
              />):              
              <Select 
                id="pk"
                name="Interests"
                label="Select General Interest"
                value={interestid}
                options={interestCategories}
                onChange={this.handleInterestChange}
              />
              }
          </Grid>
          </Container>
          {(this.state.isLoadingKeysProgs)?<Grid container className={"root"} spacing={3} justifyContent="center"><CircularProgress size={24} className="margin-top-4" /></Grid>:null}
          {programtypeid === "Core Majors" && this.state.keywordarray.length>0 ? <KeywordProgram Keywords={this.state.keywordarray} receiveDataFromChild={this.setDataReceivedFromChild} /> : ""}
          <Container ref={this.gridobj}>
            {this.state.programdata.length > 0 && 
              <ProgramCards programs={this.state.programdata} />
            }
          </Container>
          <Container>
            {relatedprograms.length > 0 && 
              <MinorsCertificatePrograms relatedprograms={relatedprograms} />
            }
          </Container>
          </Fragment>
      );
    }
  }
}

export default InterestSelect;
