import { alpha, makeStyles } from "@material-ui/core/styles";
import { standardColor } from "../../styles/palette";
import { STYLESHEET_LENGTH } from "../../util/constants";
const drawerWidth = 240;
const useStyles = makeStyles(
  (theme) => ({
    tab: {
      flexDirection: "row",
    },
    tabs: {
      borderBottomLeftRadius: "0px",
      borderBottomRightRadius: "0px",
    },
    secondaryTabs: {
      color: theme.palette.secondary.main,
    },
    tabLabelIcon: {
      minHeight: "1rem",
    },
    customTabIcon: {
      width: theme.typography.body1.fontSize,
      marginRight: theme.spacing(),
    },
    bottomNav: {
      height: "var(--mobile-nav-height)",
      padding: `8px 0px 12px 0px`,
    },
    regModeAvatar: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    darkModeAvatar: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.black,
    },
    links: {
      marginRight: theme.spacing(3),
      fontWeight: theme.typography.fontWeightMedium,
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
    },
    icon: {
      width: theme.spacing(6),
      height: "100%",
    },
    brandIcon: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    loading: {
      padding: theme.spacing(),
    },
    searchResultsContainer: {
      position: "absolute",
      transition: theme.transitions.create("height"),
      width: "100%",
      marginTop: theme.spacing(),
      [theme.breakpoints.up("sm")]: {
        width: "150%",
        left: "-50%",
      },
      [theme.breakpoints.up("md")]: {
        width: "200%",
        left: "-100%",
      },
    },
    searchHistoryBtnRoot: {
      display: "flex",
      alignItems: "center",
    },
    searchHistoryBtnLabel: {
      justifyContent: "flex-start",
    },
    searchHistoryBtnLabelText: {
      textTransform: "none",
      textAlign: "left",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    bottomNavLabel: {
      fontSize: "13px",
      // '&.$Mui-selected': {
      //     fontSize: '13px'
      // }
    },
    bottomNavAction: {
      padding: "0px 8px",
      // '&.$Mui-selected': {
      //     paddingTop: '0px'
      // }
    },
    toolbar: {
      borderBottom: `solid 3px ${standardColor.red}`,
    },
    footer: {
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        paddingBottom: `calc(var(--mobile-nav-height) + ${theme.spacing(3)}px)`,
      },
    },
    graphicsWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignSelf: "flex-start",
      [theme.breakpoints.up("md")]: {
        paddingBottom: theme.spacing(3),
      },
      [theme.breakpoints.down("sm")]: {
        paddingBottom: theme.spacing(1),
        textAlign: "left",
        flexDirection: "column",
      },
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerContainer: {
      // height: 200,
      overflow: "auto",
    },
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }),
  { index: STYLESHEET_LENGTH }
);

export default useStyles;
