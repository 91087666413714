import React from "react";
import { AppBar as MuiAppBar } from "@material-ui/core/";
import "../styles/index.css";
import PropTypes from "prop-types";
import SkipTo from "../../../Common/components/SkipTo";

function OsuNavbar(props) {
  const { elevation } = props;
  return (
    <MuiAppBar elevation={elevation} position="sticky" color="inherit">
      <div
        role="navigation"
        id="osu_navbar"
        aria-labelledby="osu_navbar_heading"
      >
        <h2 id="osu_navbar_heading" className="osu-semantic">
          Ohio State nav bar
        </h2>
        {/* <a href="#main-content" id="skip" className="osu-semantic">
          Skip to main content
        </a> */}
        <SkipTo />
        <div className="container">
          <div className="univ_info">
            <p className="univ_name">
              <a href="http://osu.edu" title="The Ohio State University">
                The Ohio State University
              </a>
            </p>
          </div>
          <div className="univ_links">
            <div className="links">
              <ul>
                <li>
                  <a href="http://www.osu.edu/help.php" className="help">
                    Help
                  </a>
                </li>
                <li>
                  <a href="http://buckeyelink.osu.edu/" className="buckeyelink">
                    BuckeyeLink
                  </a>
                </li>
                <li>
                  <a href="http://www.osu.edu/map/" className="map">
                    Map
                  </a>
                </li>
                <li>
                  <a
                    href="http://www.osu.edu/findpeople.php"
                    className="findpeople"
                  >
                    Find People
                  </a>
                </li>
                <li>
                  <a href="https://email.osu.edu/" className="webmail">
                    Webmail
                  </a>
                </li>
                <li>
                  <a href="http://www.osu.edu/search/" className="search">
                    Search Ohio State
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {props.children}
      </div>
    </MuiAppBar>
  );
}

export default OsuNavbar;

OsuNavbar.defaultProps = {
  className: "",
  elevation: 0,
  determineHeight: (height) => ({ height }),
};

OsuNavbar.propTypes = {
  elevation: PropTypes.number,
  className: PropTypes.string,
  darkMode: PropTypes.bool,
  determineHeight: PropTypes.func,
};
