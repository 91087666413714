import { SET_USER, USER_STATUS } from "../actions-index";
import { Auth } from "aws-amplify";
import { redirectToLogIn } from "../util/util";
import { transformUser } from "./transform";

export function recievedSetUser(user) {
  return {
    type: SET_USER,
    payload: transformUser(user),
  };
}

export function setUserStatus(status) {
  return {
    type: USER_STATUS,
    payload: status,
  };
}

export function setUser(forceRedirect = false) {
  return function (dispatch) {
    dispatch(setUserStatus("loading"));
    function validateSession(userResponse) {
      if (userResponse) {
        dispatch(recievedSetUser(userResponse));
      }
      return;
    }

    return Auth.currentSession()
      .then((session) => {
        validateSession(session.idToken.payload);
        if (
          session.idToken.payload.applicationRoles !== "ADMIN" &&
          session.idToken.payload.applicationRoles !== "admin"
        ) {
          dispatch(setUserStatus("error"));
        }
      })
      .catch((error) => {
        if (forceRedirect && error === "No current user") {
          redirectToLogIn();
        } else {
          setTimeout(() => {
            Auth.currentSession()
              .then((session) => {
                validateSession(session.idToken.payload);
              })
              .catch((error) => {
                setTimeout(() => {
                  Auth.currentSession()
                    .then((session) => {
                      validateSession(session.idToken.payload);
                    })
                    .catch((error) => {
                      dispatch(setUserStatus("error"));
                    });
                }, 3000); //second wait
              });
          }, 1000);
        }
      });
  };
}
