import { typeColor } from "../../Components/styles/palette";

const { standard } = typeColor;

const standardPalette = {
  background: {
    default: "#ffffff",
  },
  text: {
    default: standard.default,
  },
  textColor: standard.default,
  primary: {
    main: standard.action,
  },
  secondary: {
    main: standard.secondary,
  },
  textPrimary: {
    main: standard.action,
  },
  error: {
    main: standard.error,
  },
  boxBackground: {
    default: standard.boxbackground,
  },
};

const darkModePalette = {
  background: {
    default: "#ffffff",
  },
  text: {
    default: standard.default,
  },
  textColor: standard.default,
  primary: {
    main: standard.action,
  },
  secondary: {
    main: standard.secondary,
  },
  textPrimary: {
    main: standard.action,
  },
  error: {
    main: standard.error,
  },
  boxBackground: {
    default: standard.boxbackground,
  },
};

export { standardPalette, darkModePalette };
