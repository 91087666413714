import React, { Fragment } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CardModal from "./CardModal";
import {
  Card,
  Typography,
  CardContent,
  Grid,
  CardHeader,
} from "@material-ui/core";
import { Mui } from "@osu/react-ui";

const useStyles = makeStyles(() => ({
  root: {
    height: 400,
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    boxShadow: "0 0  0 0px rgb(255,255,255), 0.2em 0.2em 0.5em rgba(0,0,0,0.3)",
  },
  title: {
    fontSize: 16,
    height: 75,
    textAlign: "center",
    backgroundColor: "#f5f5f5",
    color: "black",
    borderBottom: "1px solid #ddd",
  },
  category: {
    fontSize: 16,
    color: "#000000S",
  },
  text: {
    color: "black",
    paddingTop: "10px",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },
  Button: {
    backgroundColor: "#bb0000",
    color: "#fff",
  },
  programCounterLabel:{
    fontSize: "14px",
    fontWeight: 500,
    color: "#333",
    float: "right",
  },
  programCount:{
    fontSize: "12px",
    paddingTop: "3px",
    padding: "6px",
    color: "#68798",
    float: "right",
  },
  hrStyle:{
    color: "#f2f2f2",
  },
  gridItemStyle:{
    position: "relative",
  },
  noPaddingLeft:{
    paddingLeft:'0px'
  }
}));
export default function ProgramCards({ programs }) {
  const classes = useStyles();
  return (
   
    <Fragment className='programCardsFragment'
    >
      <Mui.Typography className={classes.programCounterLabel}>Search Results:   <Mui.Typography className={classes.programCount}>{programs.length} items</Mui.Typography></Mui.Typography>
      <Grid
        container
        spacing={3}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        className={classes.noPaddingLeft}
      >
        {programs.map((data) => { 
            console.log("count:",programs.length)
          return (
         
            <Grid item xs={12} md={6} lg={4} key={data.pk} className={classes.gridItemStyle}>
              
              <Card className={classes.root} key={data.pk} variant="outlined">
                <CardHeader title={data.data} 
                className={classes.title}
                 />
                <CardContent>
                  <Typography variant="body1" className={classes.text}>
                     {(data.DepartmentName) ? data.DepartmentName : " "}
                  </Typography>
                  <hr className={classes.hrStyle} />
                  <Typography variant="body1" className={classes.text}>
                       {data.Description.length > 200 ? `${data.Description.substring(0,200).trim()}...` : data.Description} 
                   </Typography>
                </CardContent>

                  <CardModal
                    program={data}
                    />
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Fragment>
  );
}
