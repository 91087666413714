import React from "react";
import IconButton from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import { PROJECT_NAME } from "../../../util/constants";
import PropTypes from "prop-types";
import useStyles from "../../styles/theme";

export default function Account({
  user,
  handleLogin,
  handleLogout,
  darkMode,
  className,
}) {
  const { status = "", initials = "" } = user;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const userDropdownLabel = "Opens dropdown to log out";

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleLoginClick() {
    handleLogin();
    handleClose();
  }

  function handleLogOutClick() {
    handleLogout();
    handleClose();
  }

  const dropdown = [
    <Tooltip
      key="dropdown-button"
      aria-label={userDropdownLabel}
      title={userDropdownLabel}
    >
      <IconButton
        className={className}
        color="primary"
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Avatar className={classes[`${"reg"}ModeAvatar`]}>
          {initials}
        </Avatar>
      </IconButton>
    </Tooltip>,
    <Menu
      key="dropdown-menu"
      id="user-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem
        aria-label={`Log out of ${PROJECT_NAME}`}
        onClick={handleLogOutClick}
      >
        Log Out
      </MenuItem>
    </Menu>,
  ];

  if (status === "success") {
    sessionStorage.setItem("UserName", user.email);
    sessionStorage.setItem("UserRoles", user.applicationRoles);
    sessionStorage.setItem("UserInfo", JSON.stringify(user));
    return dropdown;
  } else if (status === "loading") {
    return <CircularProgress data-testid="loading" className={`${classes.loading}${className ? ` ${className}` : ""}`} color="secondary" />;
  }

  return (
    <Button
      className={className}
      color="primary"
      aria-label={`Log in to ${PROJECT_NAME}`}
      onClick={handleLoginClick}
    >
      Log In
    </Button>
  );
}

Account.defaultProps = {
  user: {
    status: "",
  },
  className: "",
};

Account.propTypes = {
  user: PropTypes.shape({
    status: PropTypes.oneOf(["", "error", "success", "loading"]),
    applicationRoles: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string,
    osuid: PropTypes.string,
  }),
  className: PropTypes.string,
  handleLogin: PropTypes.func,
  handleLogout: PropTypes.func,
  darkMode: PropTypes.bool,
  toggleDarkMode: PropTypes.func,
};
