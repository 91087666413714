import React from 'react';
import "../styles/index.css";

function HeroImage(props) {
  return (
    <div>
    <div className="heroImage"></div>
    <div className="slanted-1"></div>
    </div>
  );
}

export default HeroImage;