import React from "react";
import { Container } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Mui } from "@osu/react-ui";
import windowDimensions from "./windowDimensions";

  export default function MinorCertificatePrograms({ relatedprograms = [] }) {
    const ProgramRows = [];
    const { width, height } = windowDimensions();
    const displaySize = Math.round(Math.sqrt(((width * width) + (height * height)), 2) / 96);  //inches
    const customRowHeight = (displaySize <= 13) ? 70 : (displaySize > 13 && displaySize <= 18) ? 60 : 65;
    const customDensity = (displaySize <= 11) ? "comfortable" : (displaySize > 11 && displaySize <= 17) ? "standard" : "compact";

    //console.log("displaySize: ", displaySize);

    const useStyles = Mui.makeStyles((theme) => ({
        grid: {
          marginTop: theme.spacing(2),
          minHeight: `50vh`,
        },
      }));

    const classes = useStyles();

    if (relatedprograms.length > 0) {
        for (let i=0; i < relatedprograms.length; i++) {
            ProgramRows.push({
                id: relatedprograms[i].pk,
                program: relatedprograms[i].data,
                programtype: relatedprograms[i].ProgramType,
                infourl: relatedprograms[i].InfoURL,
              });
         }
    }

    const ProgramColumns = [
        {
          field: "program",
          headerName: "Program",
          flex: 1,
          renderCell: (props) => {
            const { row } = props || {};
            const {infourl} = row ?? null;
            const program = props.value

            if (infourl) {
              return (
                <Mui.Tooltip placement="right" title={`InfoURL: ${infourl}`}>
                  <Mui.Link href={`${infourl}`} target={"_blank"}>
                    {program}
                  </Mui.Link>
                </Mui.Tooltip>
              );
            }
            return program;
          },
        },
        {
          field: "programtype",
          headerName: "Program Type",
          flex: 0.5
        },
      ];

      return (
          <Container>
          <DataGrid
              overflowX="scroll"
              id="datagrid"
              rows={ProgramRows}
              columns={ProgramColumns}
              rowsPerPageOptions={[10]}
              pageSize={10}
              density={customDensity}
              rowHeight={customRowHeight}
              autoHeight
              className={classes.grid}
              disableColumnSelector
              disableSelectionOnClick
              sx={{
                  '& .MuiDataGrid-columnHeaderTitle, & .MuiDataGrid-cell' : {
                      textOverflow: "clip",
                      whiteSpace: "break-spaces",
                      lineHeight: 1.3,
                      fontSize: 15,
                  },
                  '& .MuiDataGrid-columnHeaders' : {
                      backgroundColor: "#f5f5f5",
                  },
                  '& .MuiDataGrid-columnHeaderTitle': {
                      fontSize: "18px",
                  },
                }}            
          />
          </Container>
       );
  }