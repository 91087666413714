import React, { Component } from "react";
import AppContextConsumer from "../../../App/context";
import AppBar from "./AppBar";
import OsuNavbar from "./OsuNavbar";
import PropTypes from "prop-types";

class NavBar extends Component {
	constructor() {
		super();
		this.state = {
			searchRedirect: false,
			showDrawer: false,
		};
		this.updateDrawer = this.updateDrawer.bind(this);
	}
	componentDidMount() {
		if (!Array.isArray(this.props.links) || this.props.links.length === 0) {
			this.props.getNavLinks();
		}
	}
	updateDrawer() {
		this.setState((state) => ({
			showDrawer: !state.showDrawer,
		}));
	}

	render() {
		const { props = {} } = this;
		const { className, id } = props;
		return (
			<AppContextConsumer>
				{(context = {}) => {
					const {
						darkMode,
						elevation = {},
						setNavHeight,
					} = context;
					return (
						<div>
						<OsuNavbar 
							id={id}
							elevation={elevation.navigation || 0}
							className={className}
							darkMode={darkMode}/>
						<AppBar
							determineHeight={setNavHeight}
							id={id}
							elevation={elevation.navigation || 0}
							className={className}
							darkMode={darkMode}
						>
							{/* <Hidden smDown>
								<Tooltip aria-label={schemeModeLabel} title={schemeModeLabel}>
									<IconButton
										onClick={() => {
											toggleDarkMode(!darkMode);
										}}
									>
										<Brightness4 />
									</IconButton>
								</Tooltip>
							</Hidden> */}
						</AppBar>
						</div>
					);
				}}
			</AppContextConsumer>
		);
	}
}

NavBar.defaultProps = {
	className: "",
	hideSearch: false,
	getNavLinks: () => {},
};

NavBar.propTypes = {
	className: PropTypes.string,
	toggleDarkMode: PropTypes.func,
	getNavLinks: PropTypes.func,
	links: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			url: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.shape({
					pathname: PropTypes.string.isRequired,
					search: PropTypes.string,
					hash: PropTypes.string,
					state: PropTypes.object,
				}),
			]).isRequired,
			internal: PropTypes.bool,
			affiliation: PropTypes.array,
		})
	),
};

export default NavBar;
