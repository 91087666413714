import React, { Component } from "react";
import { CssBaseline, Container } from "@material-ui/core";
import { Route } from "react-router-dom";
import { responsiveFontSizes } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import theme from "../styles/theme";
import { AppContext } from "../context";
import { standardPalette, darkModePalette } from "../styles/colors";
import Footer from "../../Components/Footer";
import NavBar from "../../Components/Navbar/containers";
import EmergencyAlert from "../../Components/EmergencyAlert/containers";
import HeroImage from "./HeroImage";
import ExploreBox from "./ExploreBox";

class PublicRoute extends Component {
  constructor(props) {
    super(props);
    const matchMedia = window.matchMedia(
      "(prefers-color-scheme: light)"
    ).matches;
    if (matchMedia) {
      this.updateColorVariables(matchMedia);
    }
    this.state = {
      darkMode: matchMedia,
      navIndex: 1,
      programdata: [],
      studentdata: [],
      title: "",
      isLoading: true,
      relatedProgramsList: [],
    };
    this.constructProviderValue = this.constructProviderValue.bind(this);
    this.setNavigationHeight = this.setNavigationHeight.bind(this);
    this.createTitle = this.createTitle.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }


  updateColorVariables(darkMode) {
    let root = document.documentElement;
    const palette = darkMode ? darkModePalette : standardPalette;
    console.log(palette);
    root.style.setProperty("--root-text-color", palette.textColor);
    root.style.setProperty(
      "--options-background-color",
      darkMode ? `var(--elevation8)` : palette.background.default
    );
    root.style.setProperty(
      "--root-background-color",
      palette.background.default
    );
    console.log(root.style);
  }
  createTitle =
    (page = "") =>
      (description = "") => {
        this.setState({
          title: `${page}${description ? ` | ${description}` : ""}`,
        });
      };
  handleCancel() {
    this.setState({ showRelatedPrograms: false });
  }
  updateColorMode(toggle = false) {
    this.setState(
      {
        darkMode: toggle,
      },
      () => this.updateColorVariables(this.state.darkMode)
    );
  }

  setNavigationHeight(height) {
    this.setState({ navHeight: height });
  }
  constructProviderValue() {
    const { navHeight } = this.state;
    return {
      mobileNavIndex: this.state.navIndex,
      darkMode: this.state.darkMode,
      navHeight,
      palette: this.state.darkMode ? darkModePalette : standardPalette,
      setNavHeight: (height) =>
        !!height && height !== navHeight && this.setNavigationHeight(height),
      elevation: this.state.darkMode
        ? {
          standard: 2,
          high: 4,
          navigation: 6,
          tabs: 6,
          drawer: 12,
        }
        : {
          standard: 1,
          high: 4,
          tabs: 2,
          navigation: 4,
          drawer: 12,
        },
    };
  }
  render() {
    const Component = this.props.component;
    const providerValue = this.constructProviderValue();

    return (
      <React.Fragment>
        <AppContext.Provider value={providerValue}>
          <ThemeProvider
            theme={responsiveFontSizes(
              theme(this.state.darkMode, providerValue.elevation)
            )}
          >
            <CssBaseline />
            <div className="main-wrapper">
              {process.env.REACT_APP_ENABLE_EMERGENCY_ALERT === "true" && (
                <EmergencyAlert className="py-1" />
              )}
              <NavBar
                hideSearch={"false"}
                className="mb-2"
              />
              <div id="main" tabIndex="-1">
              <Container component="main" className="container" style={{ paddingTop: "0px" }}>
                <Route
                  render={(props) => (
                    <Component generateTitle={props.generateTitle} />
                  )}
                />
              </Container>
              <HeroImage />
              <ExploreBox />
              </div>
            </div>
            <Footer /> 
          </ThemeProvider>
        </AppContext.Provider>
      </React.Fragment>
    );
  }
}
export default React.memo(
  PublicRoute,
  (prev, next) => JSON.stringify(prev) === JSON.stringify(next)
);
