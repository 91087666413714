const elevations = createElevations();

function createElevations() {
  let obj = {};
  for (let i = 0; i < 25; i++) {
    obj = {
      ...obj,
      ...{
        [`elevation${i}`]: {
          backgroundColor: `var(--elevation${i})`,
        },
      },
    };
  }
  return obj;
}

export default elevations;
