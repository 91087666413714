import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import App from "../components";
import { setUser } from "../../actions-index";

const mapStateToProps = (state, ownProps) => {
  const { authentication } = state;
  const { status = "", user = {} } = authentication;
  return {
    user: {
      ...user,
      status,
    },
    userIsLoading: status === "loading",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (redirectOverride) => dispatch(setUser(redirectOverride)),
  };
};

function verifyAppChanges(prevProps, nextProps) {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(React.memo(App, verifyAppChanges))
);
